<template>
  <div>
    <!-- -------------------------Evaluaciones para corregir Profesor------------------------- -->
    <Card
      v-if="user.groups.includes(6)"
      class="dashboard-card"
      :variant_sixth="true"
    >
      <template v-slot:title>
        <b-icon class="card-header-icon" icon="file-earmark-check"></b-icon>
        {{ $getVisibleNames("evaluations2.evaluation", true, "Evaluaciones") }}
        a Corregir</template
      >
      <template v-slot:content>
        <template v-if="institution && institution.kl_assessment">
          <div
            v-if="
              instrument_correction.length != 0 ||
              optionsMatterCorrectTest.length > 1 ||
              optionsCareerCorrectTest.length > 1 ||
              optionsSectionCorrectTest.length > 1 ||
              optionsCampuCorrectTest.length > 1
            "
            class="container-select mb-2 noprint"
          >
            <div>
              <b-form-select
                id="input-campus"
                class="select-form"
                v-model="select_campus_correct_test"
                :options="optionsCampuCorrectTest"
                @change="getInstrumentCorrection()"
              ></b-form-select>
            </div>
            <div>
              <b-form-select
                id="input-career"
                class="select-form"
                v-model="select_career_correct_test"
                :options="optionsCareerCorrectTest"
                @change="getInstrumentCorrection()"
              ></b-form-select>
            </div>
            <div>
              <b-form-select
                id="input-matter"
                class="select-form"
                v-model="select_matter_correct_test"
                :options="optionsMatterCorrectTest"
                @change="getInstrumentCorrection()"
              ></b-form-select>
            </div>
            <div>
              <b-form-select
                id="input-section"
                class="select-form"
                v-model="select_section_correct_test"
                :options="optionsSectionCorrectTest"
                @change="getInstrumentCorrection()"
              ></b-form-select>
            </div>
          </div>
          <template
            v-if="user.groups.includes(6) && instrument_correction.length > 0"
          >
            <b-table-simple
              bordered
              borderless
              small
              hover
              sticky-header="400px"
              class="border-table"
            >
              <b-thead>
                <b-tr>
                  <!-- Columna Nombre Evaluacion (Evaluacion)-->
                  <b-th class="primary-cell-correction-test" style="width: 11%">
                    {{
                      $getVisibleNames(
                        "evaluations2.evaluation",
                        false,
                        "Evaluación"
                      )
                    }}
                  </b-th>
                  <b-th
                    class="primary-cell-correction-test"
                    style="width: 10%"
                    >{{
                      $getVisibleNames(
                        "evaluations2.test",
                        false,
                        "Instrumento"
                      )
                    }}</b-th
                  >
                  <b-th class="primary-cell-correction-test" style="width: 3%">
                    Tipo
                  </b-th>
                  <b-th class="primary-cell-correction-test" style="width: 5%">
                    {{ $getVisibleNames("mesh.campus", false, "Sede") }}
                  </b-th>
                  <b-th class="primary-cell-correction-test" style="width: 11%">
                    {{ $getVisibleNames("mesh.career", false, "Programa") }}
                  </b-th>
                  <b-th
                    class="primary-cell-correction-test"
                    style="width: 12%"
                    >{{
                      $getVisibleNames(
                        "mesh.egressprofilematter",
                        false,
                        "Asignatura"
                      )
                    }}</b-th
                  >
                  <b-th
                    class="primary-cell-correction-test"
                    style="width: 7%"
                    >{{
                      $getVisibleNames("teaching.section", false, "Sección")
                    }}</b-th
                  >
                  <b-th class="primary-cell-correction-test" style="width: 5%"
                    >Fecha Inicio</b-th
                  >
                  <b-th class="primary-cell-correction-test" style="width: 5%"
                    >Fecha Término</b-th
                  >
                </b-tr>
              </b-thead>

              <b-tbody>
                <template v-for="test in instrument_correction">
                  <b-tr :key="test.id">
                    <!-- Nombre de la Evaluacion (Evaluacion) -->
                    <b-td class="secondary-cell-correction-test text-left">
                      <div class="mr-0">
                        {{ test.evaluation_title }}
                      </div>
                    </b-td>
                    <!-- Nombre de la Evaluacion (Test) -->
                    <b-td class="secondary-cell-correction-test text-left">
                      <span
                        class="div-href-test"
                        v-b-tooltip.v-secondary.right.noninteractive="
                          `Ir a corregir la ${$getVisibleNames(
                            'evaluations2.evaluation',
                            false,
                            'Evaluación'
                          )}`
                        "
                        @click="
                          redirectToInstrumentCorrection(
                            test.evaluation_id,
                            test.id
                          )
                        "
                      >
                        {{ test.title }}
                      </span>
                    </b-td>
                    <!-- Tipo de la Evaluacion (Evaluacion) -->
                    <b-td class="secondary-cell-correction-test text-left">
                      <div
                        class="mr-0"
                        v-b-tooltip.v-secondary.right.noninteractive="
                          `${test.evaluative_agent_name} del ${$getVisibleNames(
                            'evaluations2.test',
                            false,
                            'Instrumento'
                          )}`
                        "
                      >
                        {{ test.evaluation_type_default }}
                      </div>
                    </b-td>
                    <!-- Sede -->
                    <b-td class="secondary-cell-correction-test text-left">
                      {{ test.campus }}
                    </b-td>
                    <!-- Carrera -->
                    <b-td class="secondary-cell-correction-test text-left">
                      {{ test.career_name }}
                    </b-td>
                    <!-- Asignatura -->
                    <b-td class="secondary-cell-correction-test text-left">
                      {{ test.matter_code }} - {{ test.matter_name }}
                    </b-td>
                    <!-- Seccion -->
                    <b-td class="secondary-cell-correction-test text-left">
                      {{ test.section_name }}
                    </b-td>
                    <!-- Fecha de Inicio -->
                    <b-td class="secondary-cell-correction-test text-left">
                      <template v-if="test.start_time">
                        {{ test.start_time | FormatToDateTime }}
                      </template>
                      <template v-else> N/A </template>
                    </b-td>
                    <!-- Fecha de Termino -->
                    <b-td class="secondary-cell-correction-test text-left">
                      <template v-if="test.end_time">
                        {{ test.end_time | FormatToDateTime }}
                      </template>
                      <template v-else> N/A </template>
                    </b-td>
                  </b-tr>
                </template>
              </b-tbody>
            </b-table-simple>
          </template>

          <template
            v-if="user.groups.includes(6) && instrument_correction.length == 0"
          >
            <div class="text-left">
              No hay
              {{
                $getVisibleNames(
                  "evaluations2.evaluation",
                  true,
                  "Evaluaciones"
                )
              }}
              por corregir
            </div>
          </template>
        </template>
        <template v-else>
          <div class="text-left">
            <span>Este módulo no está habilitado para su institución</span>
          </div>
        </template>
      </template>
    </Card>
  </div>
</template>

<script>
// import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "InstrumentCorrection",
  components: {
    Card: () => import("@/components/users/Card"),
  },
  data() {
    return {
      first_fetch_correct_test: true,
      select_matter_correct_test: null,
      select_career_correct_test: null,
      select_section_correct_test: null,
      select_campus_correct_test: null,
      instrument_correction: [],
      optionsMatterCorrectTest: [],
      optionsCareerCorrectTest: [],
      optionsSectionCorrectTest: [],
      optionsCampuCorrectTest: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
    }),
  },
  methods: {
    optionsMattersCorrectTest(mattersFromApi) {
      this.optionsMatterCorrectTest = [
        {
          value: null,
          text: `Filtrar por ${this.$getVisibleNames(
            "mesh.egressprofilematter",
            false,
            "Asignatura"
          )}`,
        },
      ];
      // Crear un Map para almacenar carreras únicas
      const uniqueMatters = new Map();
      // Iterar sobre los datos de la API y llenar el Map
      mattersFromApi.forEach((x) => {
        if (!uniqueMatters.has(x.matter_name)) {
          uniqueMatters.set(x.matter_name, x.matter_id);
        }
      });
      // Convertir el Map en un array de opciones y añadirlo a optionsMatterCorrectTest
      uniqueMatters.forEach((id, name) => {
        this.optionsMatterCorrectTest.push({
          value: id,
          text: name,
        });
      });
    },
    optionsCareersCorrectTest(careersFromApi) {
      this.optionsCareerCorrectTest = [
        {
          value: null,
          text: `Filtrar por ${this.$getVisibleNames(
            "mesh.career",
            false,
            "Programa"
          )}`,
        },
      ];
      const uniqueCareers = new Map();
      careersFromApi.forEach((x) => {
        if (!uniqueCareers.has(x.career_name)) {
          uniqueCareers.set(x.career_name, x.career_id);
        }
      });
      uniqueCareers.forEach((id, name) => {
        this.optionsCareerCorrectTest.push({
          value: id,
          text: name,
        });
      });
    },
    optionsSectionsCorrectTest(sectionsFromApi) {
      this.optionsSectionCorrectTest = [
        {
          value: null,
          text: `Filtrar por ${this.$getVisibleNames(
            "teaching.section",
            false,
            "Sección"
          )}`,
        },
      ];
      const uniqueSections = new Map();
      sectionsFromApi.forEach((x) => {
        if (!uniqueSections.has(x.section_name)) {
          uniqueSections.set(x.section_name, x.section_id);
        }
      });
      uniqueSections.forEach((id, name) => {
        this.optionsSectionCorrectTest.push({
          value: id,
          text: name,
        });
      });
    },
    optionsCampusCorrectTest(campusFromApi) {
      this.optionsCampuCorrectTest = [
        {
          value: null,
          text: `Filtrar por ${this.$getVisibleNames(
            "mesh.campus",
            false,
            "Sede"
          )}`,
        },
      ];
      const uniqueCampus = new Map();
      campusFromApi.forEach((x) => {
        if (!uniqueCampus.has(x.campus)) {
          uniqueCampus.set(x.campus, x.campus_id);
        }
      });
      uniqueCampus.forEach((id, name) => {
        this.optionsCampuCorrectTest.push({
          value: id,
          text: name,
        });
      });
    },
    redirectToInstrumentCorrection(evaluation_id, instrument_id = null) {
      this.$router.push({
        name: "EvaluatorView",
        params: {
          evaluation_id: evaluation_id,
          instrument_id: instrument_id,
        },
      });
    },
    getInstrumentCorrection() {
      if (
        this.user &&
        this.user.groups.includes(6) &&
        this.institution &&
        this.institution.kl_assessment
      ) {
        let arr = [];

        if (this.select_campus_correct_test != null)
          arr.push("campus=" + this.select_campus_correct_test);
        if (this.select_career_correct_test != null)
          arr.push("career=" + this.select_career_correct_test);
        if (this.select_matter_correct_test != null)
          arr.push("matter=" + this.select_matter_correct_test);
        if (this.select_section_correct_test != null)
          arr.push("section=" + this.select_section_correct_test);

        let partial_url = "";
        if (arr.length > 0) partial_url = "?" + arr.join("&");

        this.$restful
          .Get(`/evaluations2/dashboard_teacher_correct_tests/${partial_url}`)
          .then((response) => {
            this.instrument_correction = response;
            if (this.first_fetch_correct_test) {
              this.optionsMattersCorrectTest(this.instrument_correction);
              this.optionsCareersCorrectTest(this.instrument_correction);
              this.optionsSectionsCorrectTest(this.instrument_correction);
              this.optionsCampusCorrectTest(this.instrument_correction);
              this.first_fetch_correct_test = false;
            }
          });
      }
    },
  },
  mounted() {
    this.getInstrumentCorrection();
  },
  watch: {
    user() {
      this.getInstrumentCorrection();
    },
    institution() {
      this.getInstrumentCorrection();
    },
  },
  created() {
    this.$hasPermissions();
  },
};
</script>

<style scoped>
.dashboard-card {
  display: block;
  border-radius: 7px;
  margin-top: 5px;
  margin-bottom: 15px;
  box-shadow: 0px 1px 8px -1px #9d9d9d;
  /* box-shadow: 0px 1px 5px 0px #9d9d9d; */
  transition: all 0.5s ease-out;
}
.card-header-icon {
  margin-top: 2px;
  margin-right: 0.1em;
}
.border-table {
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}
.primary-cell-correction-test {
  text-align: left;
  vertical-align: middle;
  background: #f2d1b4 !important;
  color: black !important;
  font-size: var(--primary-font-size);
  padding: 2px 2px;
}
.secondary-cell-correction-test {
  text-align: center;
  font-size: var(--secondary-font-size);
  padding: 2px 2px;
}
.div-href-test {
  color: #007bff;
}
.div-href-test:hover {
  color: #0057b1 !important;
  text-decoration: underline;
  cursor: pointer;
}
.container-select {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.select-form {
  min-width: 270px;
  font-size: var(--secondary-font-size);
  margin: 3px;
}
@media (max-width: 768px) {
  .container-select div {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .select-form {
    margin: 3px;
  }
}
@media (max-width: 576px) {
  .container-select div {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .select-form {
    margin: 3px;
  }
}
@media print {
  .secondary-cell-correction-test {
    background-color: transparent !important;
  }
}
</style>